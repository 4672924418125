import store from '@/store'
import { isTokenExpires } from '@/tools/util'

export const authGuard = async (to, from, next) => {
  const openPages = ['login', 'error-404']

  if (openPages.includes(to.name)) return next()

  const token = store.getters['auth/token']

  if (token) {
    if (isTokenExpires(token)) {
      const rememberMe = localStorage.getItem('rememberMe') === 'true'

      if (rememberMe) {
        const refreshToken = await store.dispatch('auth/getRefreshToken')

        if (!refreshToken) next({ name: 'login' })
        else next()
      } else next({ name: 'login' })
    }

    const hasRoles = Boolean(store.getters['users/listOwnerRoles']?.length)
    if (token && !hasRoles) {
      await store.dispatch('users/fetchUserRoles')
    }

    const listOwnerPermissions = JSON.parse(localStorage.getItem('permissions'))
    const storeListOwnerPermissions = store.getters['users/listOwnerPermissions']
    const userPermissions = listOwnerPermissions || storeListOwnerPermissions
    const hasPermission = userPermissions?.some(el => el === to.meta.permission)

    if (hasPermission) next()
    else next({ name: 'error-404' })
  } else {
    next({ name: 'login' })
  }

  if (!token && to.meta.requiredAuth) next({ name: 'login' })
}
