export default [
  {
    path: '/fee',
    name: 'fee',
    component: () => import('@/views/apps/fee/FeeView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'buy-sell-page',
      pageTitle: 'Create Fee',
      breadcrumb: [
        {
          text: 'Fee',
        },
      ],
    },
  },

  {
    name: 'fee-edit',
    path: '/fee/edit/:id',
    component: () => import('@/views/apps/fee/FeeEdit.vue'),
    meta: {
      requiredAuth: true,
      permission: 'buy-sell-page',
      breadcrumb: [
        {
          text: 'Create Fee',
          active: true,
          to: '/fee',
        },
        {
          text: 'Edit',
        },
      ],
    },
  },
]
